.header{
    background-color: #fff159;
    max-height: 100px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows:repeat(2, 1fr);
    
   
    
}



.second-section-header{
    margin-top: 55px;
    grid-column: 2;
    grid-row: 1;
    margin-top: 11px;
   

}

.third-section-header{
    grid-column: 3;
    grid-row: 1;
    margin-top: 14px;
    margin-left: 8px;
}

.one{
    margin-left: 1px;
    text-decoration: none;
    font-size: 15px;
    color: #333;
}

.first-input{
    width: 590px;
    height: 40px;
    margin-left: -70px;
    padding: 15px;
    font-size: 16px;
    font-family: inherit;
    box-shadow: 0 2px 15px rgb(0 0 0 / 50%);
}

.four-section-header{
    grid-column: 1;
    grid-row: 2;
    margin-left: 375px;
    margin-top: 4px;
   
    
}

.five-section-header{
    grid-column: 2;
    grid-row: 2;
    margin-top: 20px;
    margin-left: -86px;
    
}

.five-section-header  a{
    margin-left: 6px;
}

.six-section-header{
    
    grid-column: 3;
    grid-row: 2;
    margin-top: 15px;
    margin-left: -10px;
}

.six-section-header > a{
    align-self: center;
    margin-left: 6px;
}

.two{
    color: rgba(51,51,51,.6);
    text-decoration: none;
    font-size: 14px;
}

.three{
    color: #333;
    text-decoration: none;
    font-size: 14px;
}

.anounce{
    text-decoration: none;
    color: #333;
    font-size: 17px;
}

.mercado-libre{
    width: 135px;
    height: 34px;
    margin-left: 350px;
    margin-top: 11px;
}
.logo-ubication{
    position: relative;
    right: 287px;
    top: -30px;
    width: 26px;
}

.logo-flame{
    position: relative;
    top: 5px;
    right: 5px;
    width: 23px;
    height: 30px;
}

.logo-car{
    width: 20px;
    height: 20px;
    margin-left: 5px;
    
    
}

.search{
    position: relative;
    left: 1120px;
    bottom: 70px;
    width: 17px;
    height: 20px;
    
    
}
