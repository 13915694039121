.s-third-section{
    display: grid;
    grid-template-columns: 20% 30% 30% 20%;
    grid-template-rows: 100%;
    background-color: white;
    border: 2px solid gainsboro;
    max-width: 1100px;
    max-height: 103px;
    margin: auto;
    margin-top: 36px;
    
}

.s-thirteen{
grid-column: 1;
grid-row: 1;
justify-self: center;
align-self: center;

padding: 50px;

}


.s-fifteen{
    grid-column: 2;
    grid-row: 1;
    justify-self: center;
    align-self: center;
}

.s-sixteen{
    justify-self: center;
    align-self: center;
    width: 92%;
}

.s-more-information{
    height: 40px;
    width: 150px;
    text-align: center;
    margin-top: 25px;
    justify-self: center;
    align-self: center;
    background-color: blue;
    color: white;
}





/*AQUI EMPIEZA LA CUARTA SECCION*/

.s-four-section{
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20% ;
    grid-template-rows: 15% 85%;
    max-width: 1100px;
    max-height: 400px;
    margin: auto;
    margin-top: 36px;
    margin-bottom: 100px;
}

.s-container{
    background-color: white;
    width:203px;
    height: 397px;
    grid-row: 2;
    justify-self:start;
    
}

.s-container img{
    width: 185px;
    height: 189px;
    border-bottom: 1px solid gainsboro;
}

.s-container > .s-price,.s-promotion,.s-travel,.s-description{
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
    
}
.s-price{
    color:rgba(0,0,0,8);
    font-size:24px
    }

    .s-promotion{
        color:#00a650;
        font-size: 14px;
        
    }

    .s-travel{
        color:#00a650;
        font-size: 18px;
        text-decoration: none;
        margin-left: 11px;
    }

    .s-description{
        font-size: 14px;
        
    }

    .s-container > .s-button-container ,.s-a-container{
      text-decoration: none;
      color: #3483fa;
      
    }

     .s-container >.s-button-container{
        padding: 8px;
        
        justify-self: center;
        width: 175px;
        color: #3483fa;
        border: #3483fa 1px solid;
        margin-left: 13px;
        background: white;
        cursor: pointer;
    }

    .s-container >.s-button-container:hover{
      background: whitesmoke;
    }

    .s-container .s-third-image{
        width:29px;
        height:23px

    }


.s-first-title{
grid-column: 1/5;
grid-row: 1;
justify-self:start;
align-self:center;
margin-left: 10px;
color: #666;
}



.s-sub-title{
    grid-column: 3/5;
    grid-row: 1;
    margin-top: 0px;
    justify-self: center;
    align-self: center;
    margin-left: 100%;
    text-decoration: none;
    color: var(--color1);

}

.s-sub-title-1{
    grid-column: 2/3;
    grid-row: 1;
    justify-self: center;
    align-self: center;
    margin-left: 74%;
    text-decoration: none;
    color: var(--color1);

}

.s-five-section{
    display: grid;
    grid-template-columns: repeat(25, 1fr);
    margin-top: 100px;
    margin-bottom: 100px;
    
    
    
    

}

.s-container-publicity{
   background-color: white;
   max-height: 290px;
   border-radius: 10px;
   margin-top: 20px;
   margin-left: 325px;
  
   
   
   
}

.s-container-publicity,.s-img-publicity{
    grid-column: 4/5;
    
    
}

.s-container-publicity2{
    background-color: white;
    max-height: 290px;
    border-radius: 10px;
    margin-top: 20px;
 }

.s-container-publicity2,.s-img-publicity2{
    grid-column: 6/7;
}

.s-container-publicity3{
    background-color: white;
   max-height: 290px;
   border-radius: 10px;
   margin-top: 20px;
 }

.s-container-publicity3,.s-img-publicity2{
    grid-column: 8/9;
}

.s-h2-container{
  grid-row: 2;
  text-align: center;
  margin-top: 5px;
  align-self: center;
}

.s-img-publicity2{
    width: 345px;
    height: 250px;
    
   
}



