.third-section{
    display: grid;
    grid-template-columns: 20% 30% 30% 20%;
    grid-template-rows: 100%;
    background-color: white;
    border: 2px solid gainsboro;
    max-width: 1100px;
    max-height: 100px;
    margin: auto;
    margin-top: 36px;
}

.thirteen{
grid-column: 1;
grid-row: 1;
justify-self: center;
align-self: start;
}

.fourteen{
grid-column: 1;
grid-row: 1;
justify-self: start;
align-self: center;
margin-top: 25px;
margin-left: 7px;
font-size: 13px;
}

.fifteen{
    grid-column: 2;
    grid-row: 1;
    justify-self: center;
    align-self: start;
}

.sixteen{
grid-column: 2;
grid-row: 1;
justify-self: start;
align-self: center;
margin-top: 50px;
margin-left: 11px;
text-decoration: none;
color:var(--color1)
}

.image-citibanamex{
    grid-column: 3;
    grid-row: 1;
    width: 279px;
    height: 77px;
    margin-top: 13px;
}

.seventeen{
    grid-column: 4;
    grid-row: 1;
    justify-self: center;
    align-self: start;
}

.eighteen{
    grid-column: 4;
    grid-row: 1;
    justify-self: start;
    align-self: center;
    margin-top: 50px;
    margin-left: 11px;
    text-decoration: none;
    color: var(--color1)
    
}


/*AQUI EMPIEZA LA CUARTA SECCION*/

.four-section{
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20% ;
    grid-template-rows: 15% 85%;
    max-width: 1100px;
    max-height: 400px;
    margin: auto;
    margin-top: 36px;
    margin-bottom: 100px;
}

.container{
    background-color: white;
    width:203px;
    height: 372px;
    grid-row: 2;
    justify-self:start;

  
    
}

.container img{
    width:178px;
    height:210px;
    border-bottom: 1px solid gainsboro;
}

.container  .price,.promotion,.travel,.description{
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-decoration: none;
    width: 182px;
    
}
.price{
    color:rgba(0,0,0,.8);
    font-size:24px
    }

    .promotion{
        color:#00a650;
        font-size: 14px;
        margin-left: 4%;
        
    }

    .travel{
        color:#00a650;
        font-size: 18px;
        text-decoration: none;
        margin-left: 0px;
    }

    .description{
        font-size: 14px;
        color: black;
        
    }

    .container .third-image{
        width:29px;
        height:23px

    }


.first-title{
grid-column: 1;
grid-row: 1;
justify-self:start;
align-self:center;
margin-left: 10px;
color: #666;
}

.sub-title{
    grid-column: 1/3;
    grid-row: 1;
    margin-top: 15px;
    justify-self:center;
    align-self:center;
    margin-left: 50px;
    text-decoration: none;
    color:var(--color1);
    margin-right: 90px;

}



/* AQUI EMPIEZA EL CAROUSEL */




/*AQUI EMPIEZA LA QUINTA SECCION
AQUI SE REUTILIZO CODIGO PARA LA SECCION SIETE YA QUE TIENEN 
LA MISMA ESTRUCTURA
*/

.five-section{
    display:grid;
    grid-template-columns: 20% 30% 20% 30% ;
    grid-template-rows: 15% 85%;
    max-width: 1100px;
    max-height: 400px;
    margin: auto;
    margin-top: 36px;

    

}

.container-publicity{
    background-color: white;
    min-width: 386px;
    height: 260px;
    justify-self: center;
    margin-right: 245px;
    margin-top: 61px;
    
}

 .title-publicity{
    grid-column: 1;
    grid-row: 1;
    justify-self:start;
    align-self:center;
    width: 300px;
    margin-left: 10px;
    color: #666;

   

}

 .h6-publicity{
     grid-column: 1;
     grid-row: 2;
     
     margin-left: 36px;
     margin-top: 51px;
 }

 .h1-publicity{
    grid-column: 1;
    grid-column: 1;
    grid-row: 2;
    align-self: start;
    margin-left: 36px;
    margin-top: 24px;
 }

 .button-publicity{
    grid-column: 1;
    grid-row: 2;
    width: 85px;
    height: 34px;
    align-self: center;
    background-color: #3483fa;
    color: black;
    margin-left: 36px;
    

 }

 .button-publicity a{
    text-decoration: none;
    color:white;
    margin-left: 0;
 }

 .button-publicity:hover{
background-color: darkblue;
 }

 .img-publicity{
    grid-column: 2;
    grid-row: 2;
    justify-self: end;
    align-self: start;
    width: 248px;
    height: 259px;
    margin-left: 273px;
    margin-top: -204px;
 } 


 .container-publicity2{
    background-color: white;
    min-width: 538px;
    height: 260px;
    justify-self: center;
   margin-left: 310px;
    margin-top: 61px;
    
}


 .h6-publicity2{
     grid-column: 3;
     grid-row: 2;
     margin-left: 36px;
     margin-top: 51px;
 }

 .h1-publicity2{
    grid-column: 3;
    grid-row: 2;
    align-self: start;
    margin-left: 36px;
    margin-top: 24px;
 }

 .button-publicity2{
    grid-column: 3;
    grid-row: 2;
    width: 85px;
    height: 34px;
    align-self: center;
    background-color: #3483fa;
    color: black;
    margin-left: 36px;
    

 }

 .button-publicity2 a{
    text-decoration: none;
    color:white;
    margin-left: 0;
 }

 .button-publicity2:hover{
background-color: darkblue;
 }

 .img-publicity2{
    grid-column: 4;
    grid-row: 2;
    justify-self: end;
    align-self: start;
    width: 267px;
    height: 243px;
    margin-left: 255px;
    margin-top: -196px;
 } 


 .seven-section{
    display:grid;
    grid-template-columns: 20% 30% 20% 30% ;
    grid-template-rows: 15% 85%;
    max-width: 1100px;
    max-height: 400px;
    margin: auto;
    margin-top: 36px;
 }

 /*AQUI EMPIEZA LA SECCION OCHO*/

 .eight-section{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 20% 40% 40%;
    max-width: 1125px;
    min-height: 400px;
    margin: auto;
    margin-top: 36px;
    font-size: 15px;
    padding-left: 39px;
    margin-bottom: 200px;
    
   
    
 }

 .eight-section-image{
    width: 99px;
    height: 75px;
    margin-left: 17px;
    margin-top: 23px;
    
 }

 .eight-section-title{
grid-column: 1/3;
grid-row: 1;
color: #666;
margin-left: 20px;



 }

 .eight-section-container-1{
     display: grid;
     background-color:white;
     width: 135px;
     height: 159px;
     grid-column: 1;
     grid-row: 2;
     text-decoration: none;
     color: black;
     margin-left: 1px;
     
     
 }


  .eight-section-container-1:hover{
   background-color: #3483fa;
  }   
     
     

 

 .eight-section-container-2{
    display: grid;
    background-color:white;
    width: 135px;
    height: 159px;
    grid-column: 2;
    grid-row: 2;
    margin-left: 1px;
    text-decoration: none;
     color: black;
     margin-left: -6px;
}

.eight-section-container-2:hover{
    background-color: #3483fa;
}

.eight-section-container-3{
    display: grid;
    background-color:white;
    width: 135px;
    height: 174px;
    grid-column: 3;
    grid-row: 2;
    margin-left: 1px;
    text-decoration: none;
     color: black;
     margin-left: -14px;
    

}

.eight-section-container-3:hover{
    background-color: #3483fa;
}

.eight-section-container-4{
    display: grid;
    background-color:white;
    width: 135px;
    height: 159px;
    grid-column: 4;
    grid-row: 2;
    margin-left: 1px;
    text-decoration: none;
     color: black; 
     margin-left: -22px;

}

.eight-section-container-4:hover{
    background-color: #3483fa;
}

.eight-section-container-5{
    display: grid;
    background-color:white;
    width: 135px;
    height: 159px;
    grid-column: 5;
    grid-row: 2;
    margin-left: 1px;
    text-decoration: none;
     color: black; 
     margin-left: -30px;

}

.eight-section-container-5:hover{
    background-color: #3483fa;
}

.eight-section-container-6{
    display: grid;
    background-color:white;
    width: 135px;
    height: 159px;
    grid-column: 6;
    grid-row: 2;
    margin-left: 1px;
    text-decoration: none;
     color: black;
     margin-left: -38px;

}

.eight-section-container-6:hover{
    background-color: #3483fa;
}

.eight-section-container-7{
    display: grid;
    background-color:white;
    width: 135px;
    height: 159px;
    grid-column: 7;
    grid-row: 2;
    margin-left: 1px;
    text-decoration: none;
     color: black;
     margin-left: -45px;

}

.eight-section-container-7:hover{
    background-color: #3483fa;
}

.eight-section-container-8{
    display: grid;
    background-color:white;
    width: 135px;
    height: 159px;
    grid-column: 1;
    grid-row: 3;
    margin-top: -4px;
    text-decoration: none;
     color: black;
     margin-left: 1px;
    

}

.eight-section-container-8:hover{
    background-color: #3483fa;
}

.eight-section-container-9{
    display: grid;
    background-color:white;
    width: 135px;
    height: 159px;
    grid-column: 2;
    grid-row: 3;
    margin-left: 158px;
    margin-top: -4px;
    margin-left: 1px;
    text-decoration: none;
     color: black;
     margin-left: -6px;

}

.eight-section-container-9:hover{
    background-color: #3483fa;
}

.eight-section-container-10{
    display: grid;
    background-color:white;
    width: 135px;
    height: 159px;
    grid-column: 3;
    grid-row: 3;
    margin-left: 316px;
    margin-top: -4px;
    margin-left: 1px;
    text-decoration: none;
     color: black;
     margin-left: -14px;

}

.eight-section-container-10:hover{
    background-color: #3483fa;
}

.eight-section-container-11{
    display: grid;
    background-color:white;
    width: 135px;
    height: 159px;
    grid-column: 4;
    grid-row: 3;
  margin-left: 1px;
    margin-top: -4px; 
    text-decoration: none;
     color: black;
     margin-left: -22px;

}

.eight-section-container-11:hover{
    background-color: #3483fa;
}

.eight-section-container-12{
    display: grid;
    background-color:white;
    width: 135px;
    height: 159px;
    grid-column: 5;
    grid-row: 3;
    margin-left: 1px;
    margin-top: -4px;
    text-decoration: none;
     color: black;
     margin-left: -30px;

}

.eight-section-container-12:hover{
    background-color: #3483fa;
}

.eight-section-container-13{
    display: grid;
    background-color:white;
    width: 135px;
    height: 159px;
    grid-column: 6;
    grid-row: 3;
    margin-left:1px;
    margin-top: -4px; 
    text-decoration: none;
     color: black;
     margin-left: -38px;

}

.eight-section-container-13:hover{
    background-color: #3483fa;
}

.eight-section-container-14{
    display: grid;
    background-color:white;
    width: 135px;
    height: 159px;
    grid-column: 7;
    grid-row: 3;
    margin-left: 1px;
    margin-top: -4px;
    text-decoration: none;
     color: black;
     margin-left: -45px;
    

}

.eight-section-container-14:hover{
    background-color: #3483fa;
}



 
  
  .eight-section-h3{
   
   align-self: center;
   justify-self:center;
   text-align: center;
    
   
 }

 .nine-section{
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20% ;
    min-height: 364px;
    max-width: 2560px;
    margin: auto;
    margin-top: 36px;
    background-color: white;
    
 }



 .nine-section-container-1{
grid-column:2;
text-align:center;
min-height: 200px;


}
 .nine-section-container-2{
    grid-column:3;
    text-align:center;
    min-height: 200px;
    margin-left:20px
 }

 .nine-section-container-3{
grid-column:4;
text-align:center;
min-height: 200px;
margin-left: 20px;
 }

 .nine-section-img{
    margin-top: 45px;
    width: 86px;
    height: 159px;
    margin-bottom: -32px;
     
 }

 .container-a{
     text-decoration: none;
     color: #3483fa;
     margin-right: 5px;
 }

 .nine-section-h1{
font-size: 20px;
 }

 .nine-section-p{
     font-size: 15px;
     color: #999;
     
 }

 