.slider{
    width: 95%;
    margin: auto;
    overflow: hidden;
    grid-column: 2;
}

.slider ul{
    display: flex;
    padding: 0;
    width: 400%;
    animation: cambio 10s infinite alternate;
}

.slider li{
    width: 100%;
    list-style: none;
}

.slider img{
    width: 100%;
    height: 500px;
    
    
}

@keyframes cambio{
    0%{margin-left:0;}
    20% {margin-left:  0;}
   
    25% {margin-left:  -100%;}
    45% {margin-left:  -100%;}
   
    50% {margin-left:  -200%;}
    70% {margin-left:  -200%;}
   
    75% {margin-left:  -300%;}
    100% {margin-left:  -300%;}
} 


.publicity{
grid-column: 2;
grid-row: 1;
display: grid;
justify-self: center;
height: 400px;
width: 1559px;
}

.second-section{
display: grid;
grid-template-columns: 10% 80% 10%;
grid-template-rows: 70% 30%;
margin-top: -10px;

}


